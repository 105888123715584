<template>
  <div
    class="formdata"
    style="padding: 20px;"
  >
    <!-- 复诊订单 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
    >
      <h4 class="title">
        订单基本信息
      </h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="业务订单号 :">
            <span>{{ form.id }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="下单时间 :">
            <span>{{ form.createTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.completeTime">
        <el-col :span="10">
          <el-form-item label="结束时间 :">
            <span>{{ form.completeTime }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="服务时长 :">
            <span>{{ queryDuration(form) }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="问诊方式 :">
            <span>{{ consultationTypeDic[$route.query.type] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="就诊时间  :">
            <span>{{ form.record&&form.record.firstReplayDate||'' }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="10">
          <el-form-item label="处方订单 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="开方时间 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="10">
          <el-form-item label="订单业务状态 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="订单价格 :">
            <span>{{ form.doctorSettlementFee }}</span>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="10">
          <el-form-item label="审方时间 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="支付价格 :">
            <span>{{ form.payFee }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">
        医生基本信息
      </h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="医生团队名称 :">
            <span>{{ form.doctor&&form.doctor.teamName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="科室名称 :">
            <span>{{ form.doctor&&form.doctor.deptName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="医生姓名 :">
            <span>{{ form.doctor&&form.doctor.doctorName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="年龄 :">
            <span>{{ form.doctor&&getDoctorAge(form.doctor.idCard) }}岁</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.mobile }}</span> -->
            <span v-if="!allPhoneD">{{ form.doctor&&form.doctor.mobile?form.doctor.mobile:''|hidePhone }}</span>
            <span v-else>{{ form.doctor&&form.doctor.mobile }}</span>
            <i
              class="el-icon-view"
              @click="isShow(0)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="医生职称 :">
            <span>{{ form.doctor&&form.doctor.professionalTitleName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="组员医生 :">
        {{ doctorNameList }}
      </el-form-item>
      <h4 class="title">
        患者基本信息
      </h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="患者名称 :">
            <span>{{ form.patientName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.appointment.patientMobile }}</span> -->
            <span v-if="!allPhoneP">{{ form.patientMobile?form.patientMobile:''|hidePhone }}</span>
            <span v-else>{{ form.patientMobile }}</span>
            <i
              class="el-icon-view"
              @click="isShow(1)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="性别 :">
            <span>{{ sexDic[form.patientSex] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="年龄 :">
            <span>{{ form.patientAge }}岁</span>
          </el-form-item>
        </el-col>
      </el-row>

      <template v-if="form.consultationStatus==='CANCELED'||form.consultationStatus==='REFUSE'">
        <h4 class="title">
          取消原因
        </h4>
        <el-form-item>
          <span>{{ form.consultationStatus==='CANCELED'?form.cancelReason:form.refuseReason }}</span>
        </el-form-item>
      </template>
      <template v-if="form.consultationStatus==='REFUSE'">
        <h4 class="title">
          驳回原因
        </h4>
        <el-form-item>
          <span>{{ refuseReasonDic[form.refuseReason] }}</span>
        </el-form-item>
      </template>
      <el-form-item class="goBack">
        <el-button
          type="primary"
          @click="$router.go(-1)"
        >
          返回
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import {
    orderStatusList,
    sexDic,
    refuseReasonDic,
    formatWebUrl
  } from '@/utils/component/publicList';
  import { getDuration } from '@/utils/index';

  export default {
    name: 'GraphicDetails',
    data() {
      return {
        sexDic: sexDic,
        refuseReasonDic: refuseReasonDic, // 医生退诊理由
        allPhoneD: false, // 是否展示完全的电话号码
        allPhoneP: false, // 是否展示完全的电话号码
        consultationTypeDic: JSON.parse(this.$route.query.consultationTypeDic), // 问诊类型字典
        orderStatusList: orderStatusList, // 订单业务状态
        form: {},
        doctorTeams: [],
        summaryDetail: {}, // 问诊小结
        prescriptionList: [], // 处方详情
        rules: {}
      };
    },
    computed: {
      doctorNameList() {
        const { doctorTeams } = this;
        if (doctorTeams !== undefined && doctorTeams.length > 0) {
          const list = [];
          doctorTeams.forEach((v) => {
            list.push(v.doctorName);
          });
          return list.join(',');
        }
        return '';
      }
    },
    watch: {},
    mounted() {
      this.getOrderDetail();
    },
    methods: {
      ...mapActions('doctorManage', ['textQueryDetail']),
      // 电话号码显示隐藏切换
      isShow(index) {
        if (index === 0) {
          this.allPhoneD = !this.allPhoneD;
        } else {
          this.allPhoneP = !this.allPhoneP;
        }
      },
      // 获取医生年龄
      getDoctorAge(UUserCard) {
        console.log(UUserCard, '11111111111111');
        // 获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
        if (
          UUserCard.substring(10, 12) < month ||
          (UUserCard.substring(10, 12) === month &&
          UUserCard.substring(12, 14) <= day)
        ) {
          age++;
        }
        return age;
      },
      // 订单详情
      getOrderDetail() {
        const param = {
          id: this.$route.query.orderDetailNum
        };
        this.$api.furtherConsultationDetail(param).then((res) => {
          console.log(res, 7777777);
          this.form = res.data;
          this.doctorTeams = res.data.doctorTeams;
          if (res.data.hasSummary) {
            console.log(res, 666666);
            this.querySummary(res.data.orderNo);
          }
          if (res.data.hasPrescription) {
            this.queryPrescriptionDetail(res.data.orderNo);
          }
        });
      },
      // el-image点击图片页面卡顿问题
      solveRefresh() {
        document.body.style = '';
      },
      // 查询问诊小结
      querySummary(orderNo) {
        const param = {
          consultationType: this.$route.query.type,
          consultationOrderNo: orderNo
        };
        this.$api.summaryDetail(param).then((res) => {
          this.summaryDetail = res.data;
        });
      },
      // 查询处方详情
      queryPrescriptionDetail(orderNo) {
        const param = {
          consultationOrderNo: orderNo,
          consultationType: this.$route.query.type
        };
        this.$api.prescriptionDetail(param).then((res) => {
          this.prescriptionList = res.data;
        });
      },
      // 跳转到处方详情
      goPrescriptionDetail(item) {
        this.$router.push({
          path: '/orderManage/viewPrescriptionDetail',
          query: {
            prescriptionDetailData: JSON.stringify(item)
          }
        });
      },
      // 查看预问诊详情
      viewPreInquiry(id) {
        var address = 'https://' + window.location.hostname;
        var url = formatWebUrl({
          path: `${address}/operation-gateway/doctor-management-aggregate-service/ignore/customer-zuoyi/getResultUrl`,
          query: {
            thirdPartyOrderNo: id
          }
        });
        window.open(url);
      },
      // 获取时长
      queryDuration(row) {
        const { createTime, completeTime } = row;
        return getDuration(createTime, completeTime);
      },
    },
  };
</script>

<style lang="scss" >
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.imgstyle {
  display: flex;
  //  justify-content:space-between;
}
.prescription {
  display: flex;
  justify-content: space-between;
}
.btn {
  margin: 0px 0px 50px 50px;
}
.text-dianosisName {
  margin: 30px 50px;
}
.consultationBtn {
  margin: 20px 50px;
}
.goBack {
  margin-left: 41%;
}
</style>
